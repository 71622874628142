import React from "react"
import { Card, CardBody } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const AboutPage = () => (
  <Layout pageTitle="About">
    <SEO title="About" />
    <Card>
      <CardBody className="marginated">
        
          <h5>About High Voltage Code</h5>
          <p>
            HVC is a blog dedicated to passionate makers, hackers, and problem
            solvers. If you love to build, break and repair things, you came to the right
            place.
          </p>
          <p>Here on High Voltage Code, you'll find guides and tutorials on how to
          build real world stuff while learning electronics and programming.</p>

          <p>Aimed at beginners and advanced alike. Projects from A to Z using
          Raspberry Pi, Arduino, ESP8266 and similar single board computers or
          development boards. </p>
          <p></p>
          <p>HVC is founded and maintained by Joseph P.</p>
          <h5>About Joseph</h5>
          <p>Software developer who started HVC in 2019 to share and
          document his work.</p>
          <p> Believes that coding is a fundamental skill and
          everyone should possess it to some degree. 
          </p>
          <p>He also believes that the
          best way to learn new things is to have fun while doing so. </p>
          <p>Loves music and plays guitar.</p>

          <p>You can contact me using the <Link to={'/contact'}>contact form</Link>. </p>

          
        
      </CardBody>
    </Card>
  </Layout>
)

export default AboutPage
